export const FirebaseConfig = {
	"projectId": "makemycab-users",
	"appId": "1:811103601956:web:34c665398d8ae12b509fb1",
	"databaseURL": "https://makemycab-users-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "makemycab-users.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAfBHomNPIrVDMTgORm2z0SrccBI4YaJSw",
	"authDomain": "makemycab-users.firebaseapp.com",
	"messagingSenderId": "811103601956",
	"measurementId": "G-K97EECSL1B"
};